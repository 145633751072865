import { render, staticRenderFns } from "./QuestionFormalIntro.vue?vue&type=template&id=68fe6735&scoped=true&"
import script from "./QuestionFormalIntro.vue?vue&type=script&lang=js&"
export * from "./QuestionFormalIntro.vue?vue&type=script&lang=js&"
import style0 from "./QuestionFormalIntro.vue?vue&type=style&index=0&id=68fe6735&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68fe6735",
  null
  
)

export default component.exports