<template>
  <QuestionFormalIntro></QuestionFormalIntro>
</template>
<script>
import QuestionFormalIntro from '@/components/realFrame/QuestionFormalIntro.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AppIntro',
  components: {
    QuestionFormalIntro,
  },
  computed: {
    ...mapGetters(['getAppUserToken']),
  },
  methods: {
    ...mapActions(['sentApp']),
  },
  beforeRouteUpdate(to, from, next) {
    //問券結束頁返回問券列表
    if (from.query.type === 'end') {
      if (this.getAppUserToken?.enter_page === 'app-intro') {
        this.sentApp({
          action: 'dismissBrowser',
        });
      } else {
        this.$router.go(-1);
      }
    }
    next();
  },
};
</script>
