<template>
  <div
    class="app"
    :style="{ minHeight: dialogVisible ? '' : '-webkit-fill-available' }"
  >
    <!-- 起始頁 -->
    <section
      v-show="type === 'start'"
      style="
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      "
    >
      <div
        style="overflow: scroll"
        :style="{
          minHeight:
            $route.query.dialog === 'PreviewQuestions'
              ? 'calc(70vh - 120px)'
              : 'calc(100vh - 120px)',
          maxHeight:
            $route.query.dialog === 'PreviewQuestions'
              ? 'calc(70vh - 120px)'
              : 'calc(100vh - 120px)',
        }"
      >
        <div class="title">{{ getQuestionnaire.title }}</div>
        <img
          v-if="getQuestionnaire.image !== ''"
          :src="getQuestionnaire.image"
          width="100%"
          class="lazy-img"
          style="margin-top: 20px"
        />
        <div
          class="content"
          :style="{ marginTop: getQuestionnaire.image === '' ? '40px' : '' }"
          v-html="getQuestionnaire.content"
        ></div>
      </div>
      <!-- footer -->
      <figure
        :class="[
          $route.query.dialog === 'PreviewQuestions'
            ? 'preview-footer'
            : 'footer',
        ]"
      >
        <div class="submit-btn">
          <el-button
            round
            style="
              background-color: rgb(48, 163, 215);
              color: #ffffff;
              font-weight: 400;
              width: 80%;
              margin: 0 auto;
            "
            @click="start"
          >
            開始作答
          </el-button>
        </div>
        <div class="info-text">*限時30分鐘內填寫完*</div>
      </figure>
    </section>

    <!-- 結束頁（感謝頁） -->
    <section
      v-show="type === 'end'"
      style="
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      "
    >
      <div
        style="overflow: scroll"
        :style="{
          minHeight:
            $route.query.dialog === 'PreviewQuestions'
              ? 'calc(70vh - 120px)'
              : 'calc(100vh - 140px)',
          maxHeight:
            $route.query.dialog === 'PreviewQuestions'
              ? 'calc(70vh - 120px)'
              : 'calc(100vh - 140px)',
        }"
      >
        <div class="title">
          辛苦了～
          <br />
          雲寶感謝您的時間與用心！
          <br />
          <span v-show="getQuestionnaire.feedback_point !== 0">
            請至「雲寶點數」查看任務好禮！
          </span>
        </div>
        <hr style="border-color: #ebebeb" />

        <img
          v-if="detail.thank_image !== ''"
          :src="detail.thank_image"
          width="100%"
          style="margin: 20px 0"
          class="lazy-img"
        />
        <img
          v-else
          src="../../assets/image/cloudbabyEnd.png"
          width="203px"
          height="149px"
          style="margin: 15px auto; display: block"
        />

        <div
          class="content"
          style="color: #4a4a4a; word-break: break-all; text-align: justify"
          v-html="detail.thank_word"
        ></div>
      </div>

      <div
        :class="[
          $route.query.dialog === 'PreviewQuestions'
            ? 'preview-footer'
            : 'footer',
        ]"
      >
        <div class="btn-group">
          <el-button
            v-show="getQuestionnaire.feedback_point !== 0"
            @click="goToPoint"
            round
            style="
              background-color: #fff;
              color: #30a3d7;
              border: solid 1.5px #30a3d7;
              min-width: 152px;
            "
          >
            查看雲寶點數
          </el-button>
          <el-button
            round
            style="
              background-color: rgb(48, 163, 215);
              color: #fff;
              font-weight: 400;
              min-width: 152px;
            "
            :style="{
              width: getQuestionnaire.feedback_point === 0 ? '80%' : '',
            }"
            @click="handleEnd"
          >
            確定
          </el-button>
        </div>

        <div style="color: #b0afb0; font-size: 12px; text-align: center">
          若此份問卷有包含不適當內容，歡迎來信或來電告知：
          <br />
          support@qmartech.com / 02-2555-0660
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import {
  startFillQuestionnaire,
  getFinishInfo,
  getQuestionnaireDetail,
} from '@/api/targetQ';
import _isEmpty from 'lodash/isEmpty';

export default {
  name: 'QuestionFormalIntro',
  data() {
    return {
      detail: {},
      type: '',
    };
  },
  props: {
    dialogVisible: { type: Boolean, default: false },
    closeDialog: Function,
    isStartFill: { type: Boolean, default: false },
    screenType: { type: String, default: 'start' },
  },
  mounted() {
    this.setStyle();
    this.type = this.dialogVisible
      ? this.screenType
      : this.$route.query?.type ?? 'start';
    this.getPageInfo();
  },
  computed: {
    ...mapGetters(['getQuestionnaire', 'getAppUserToken', 'getUserAccount']),
  },
  watch: {
    dialogVisible() {
      this.type = this.dialogVisible
        ? this.screenType
        : this.$route.query?.type ?? 'start';
      this.getPageInfo();
    },
  },
  methods: {
    ...mapActions(['sentApp', 'setAppToken', 'setCurrentQuestionnaire']),
    getPageInfo() {
      if (this.dialogVisible) {
        this.setAppToken({
          ...this.getAppUserToken,
          ...{ hash_user_id: 'PRE' + this.getUserAccount.user_id },
        });
      }
      if (this.type === 'end') {
        getFinishInfo(this.getAppUserToken).then((res) => {
          this.detail = res;
        });
      } else {
        if (_isEmpty(this.getQuestionnaire)) {
          let query = {
            ...this.getAppUserToken,
            questionnaire_id: this.$route.query.id,
          };
          getQuestionnaireDetail(query).then((res) => {
            this.detail = res;
            this.setCurrentQuestionnaire(res);
          });
        } else {
          this.detail = this.getQuestionnaire;
        }
      }
    },
    start() {
      startFillQuestionnaire({
        hash_user_id: this.getAppUserToken.hash_user_id,
        questionnaire_id: this.detail.id,
      }).then((res) => {
        //if (!res.error) {
        this.setAppToken({
          ...this.getAppUserToken,
          ...res,
        });

        if (this.dialogVisible) {
          this.$emit('update:screenType', 'inProgress');
          this.$emit('update:isStartFill', true);
        } else {
          this.$router.push({
            path: '/app',
            query: {
              id: this.detail.id,
              access_token: this.$route.query.access_token,
            },
          });
        }
        // } else {
        //   this.handleQuestionnaireError();
        // }
      });
    },
    handleQuestionnaireError() {
      if (this.$route.query.hash_user_id) {
        this.sentApp({
          action: 'dismissBrowser',
        });
      } else {
        this.$router.back();
      }
    },
    goToPoint() {
      this.sentApp({
        action: 'setLogEvent',
        payload: { name: '點擊_查看雲寶點數' },
      });
      this.sentApp({
        action: 'dismissBrowser',
        payload: {
          next_action: {
            action: 'goPointMoreInfo',
            payload: {},
          },
        },
      });
    },
    handleEnd() {
      if (this.dialogVisible) {
        this.closeDialog();
      } else {
        this.sentApp({
          action: 'setLogEvent',
          payload: { name: '點擊_確定' },
        });
        // 返回列表頁，判斷從推播進入 or banner
        if (this.$store.getters.getAppUserToken?.enter_page === 'app-intro') {
          //推播
          this.sentApp({
            action: 'dismissBrowser',
          });
        } else {
          //banner
          this.$router.go(-2);
        }
      }
    },
    setStyle() {
      let vh = window.innerHeight;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
};
</script>
<style lang="scss" scoped>
figure {
  margin: 0;
}
.title {
  font-size: 20px;
  color: #4a4a4a;
  font-weight: 500;
  font-family: PingFangTC-Medium;
  text-align: center;
  margin-top: 18px;
}

.content {
  margin: 20px 0 30px 0;
  text-align: justify;
}

hr {
  margin: 20px 0;
}

.btn-group {
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
}

.info-text {
  font-family: PingFangTC;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.95px;
  text-align: center;
  color: #4a4a4a;
}

.submit-btn {
  padding: 0;
  margin-bottom: 6px;
}
/deep/ .el-button {
  font-weight: 500 !important;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 17px;
  width: 100%;
  background-color: #fff;
}

.preview-footer {
  padding-top: 30px;
}
</style>
