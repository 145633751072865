var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app",style:({ minHeight: _vm.dialogVisible ? '' : '-webkit-fill-available' })},[_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'start'),expression:"type === 'start'"}],staticStyle:{"display":"flex","justify-content":"space-between","flex-direction":"column"}},[_c('div',{staticStyle:{"overflow":"scroll"},style:({
        minHeight:
          _vm.$route.query.dialog === 'PreviewQuestions'
            ? 'calc(70vh - 120px)'
            : 'calc(100vh - 120px)',
        maxHeight:
          _vm.$route.query.dialog === 'PreviewQuestions'
            ? 'calc(70vh - 120px)'
            : 'calc(100vh - 120px)',
      })},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.getQuestionnaire.title))]),(_vm.getQuestionnaire.image !== '')?_c('img',{staticClass:"lazy-img",staticStyle:{"margin-top":"20px"},attrs:{"src":_vm.getQuestionnaire.image,"width":"100%"}}):_vm._e(),_c('div',{staticClass:"content",style:({ marginTop: _vm.getQuestionnaire.image === '' ? '40px' : '' }),domProps:{"innerHTML":_vm._s(_vm.getQuestionnaire.content)}})]),_c('figure',{class:[
        _vm.$route.query.dialog === 'PreviewQuestions'
          ? 'preview-footer'
          : 'footer' ]},[_c('div',{staticClass:"submit-btn"},[_c('el-button',{staticStyle:{"background-color":"rgb(48, 163, 215)","color":"#ffffff","font-weight":"400","width":"80%","margin":"0 auto"},attrs:{"round":""},on:{"click":_vm.start}},[_vm._v(" 開始作答 ")])],1),_c('div',{staticClass:"info-text"},[_vm._v("*限時30分鐘內填寫完*")])])]),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'end'),expression:"type === 'end'"}],staticStyle:{"display":"flex","justify-content":"space-between","flex-direction":"column"}},[_c('div',{staticStyle:{"overflow":"scroll"},style:({
        minHeight:
          _vm.$route.query.dialog === 'PreviewQuestions'
            ? 'calc(70vh - 120px)'
            : 'calc(100vh - 140px)',
        maxHeight:
          _vm.$route.query.dialog === 'PreviewQuestions'
            ? 'calc(70vh - 120px)'
            : 'calc(100vh - 140px)',
      })},[_c('div',{staticClass:"title"},[_vm._v(" 辛苦了～ "),_c('br'),_vm._v(" 雲寶感謝您的時間與用心！ "),_c('br'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.getQuestionnaire.feedback_point !== 0),expression:"getQuestionnaire.feedback_point !== 0"}]},[_vm._v(" 請至「雲寶點數」查看任務好禮！ ")])]),_c('hr',{staticStyle:{"border-color":"#ebebeb"}}),(_vm.detail.thank_image !== '')?_c('img',{staticClass:"lazy-img",staticStyle:{"margin":"20px 0"},attrs:{"src":_vm.detail.thank_image,"width":"100%"}}):_c('img',{staticStyle:{"margin":"15px auto","display":"block"},attrs:{"src":require("../../assets/image/cloudbabyEnd.png"),"width":"203px","height":"149px"}}),_c('div',{staticClass:"content",staticStyle:{"color":"#4a4a4a","word-break":"break-all","text-align":"justify"},domProps:{"innerHTML":_vm._s(_vm.detail.thank_word)}})]),_c('div',{class:[
        _vm.$route.query.dialog === 'PreviewQuestions'
          ? 'preview-footer'
          : 'footer' ]},[_c('div',{staticClass:"btn-group"},[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.getQuestionnaire.feedback_point !== 0),expression:"getQuestionnaire.feedback_point !== 0"}],staticStyle:{"background-color":"#fff","color":"#30a3d7","border":"solid 1.5px #30a3d7","min-width":"152px"},attrs:{"round":""},on:{"click":_vm.goToPoint}},[_vm._v(" 查看雲寶點數 ")]),_c('el-button',{staticStyle:{"background-color":"rgb(48, 163, 215)","color":"#fff","font-weight":"400","min-width":"152px"},style:({
            width: _vm.getQuestionnaire.feedback_point === 0 ? '80%' : '',
          }),attrs:{"round":""},on:{"click":_vm.handleEnd}},[_vm._v(" 確定 ")])],1),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"color":"#b0afb0","font-size":"12px","text-align":"center"}},[_vm._v(" 若此份問卷有包含不適當內容，歡迎來信或來電告知： "),_c('br'),_vm._v(" support@qmartech.com / 02-2555-0660 ")])}]

export { render, staticRenderFns }